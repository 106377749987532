@keyframes overlayFill {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.nft-left-overlay, .nft-right-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  background: linear-gradient(45deg, rgba(0, 234, 255, 0.8), rgba(0, 255, 135, 0.8));
  transition: width 0.1s linear, opacity 0.3s ease-in-out;
}

.animate-left {
  animation: overlayFill 2s ease-in-out forwards;
}

.animate-right {
  animation: overlayFill 2s ease-in-out 2s forwards;
  opacity: 1;
}

.nft-right-overlay:not(.animate-right) {
  opacity: 0;
}

/* General Styles */
* {
  margin: 0;
  padding: 0;
}
body {
  background: black;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
section {
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.nft-listing {
  margin: 0 -6.5px;
  width: 100%;
  display: flex;
  gap: 9.75px;
  flex-wrap: wrap;
}
.NFT-box {
  /*width: calc(100% / 4 - 19.5px);*/
}
.nft-inner {
  width: 78px;
  height: 78px;
  margin: 0 auto;
  perspective: 227.5px;
}
.nft-cube {
  width: 78px;
  height: 78px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-52px);
  transition: transform 1s;
}
.nft-cube-left, .nft-cube-right {
  position: absolute;
  width: 78px;
  height: 78px;
  line-height: 78px;
  font-size: 19.5px;
  font-weight: bold;
  color: white;
  text-align: center;
  transition: 0.6s;
}
.nft-cube-left {
  transform: rotateY(-38deg) translateZ(41px);
  background: repeating-linear-gradient(
    90deg,
    #232323 0%,
    #2d2d2d 2%,
    #232323 4%,
    #1a1a1a 6%
  );
  background-size: 6.5px 6.5px;
  filter: brightness(0.9);
}
.nft-cube-right {
  transform: rotateY(48deg) translateZ(41px);
  /*border: 2px solid #32cd32;*/
  background: repeating-linear-gradient(
    -90deg,
    #2b2b2b 0%,
    #333333 2%,
    #2b2b2b 4%,
    #1f1f1f 6%
  );
  background-size: 6.5px 6.5px;
  filter: brightness(1);
}

/* ✨ Metallic Glow Effect */
.nft-cube-left::before,
.nft-cube-right::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.2) 80%);
  opacity: 0.6;
  z-index: 1;
  pointer-events: auto;
}

.nft-title {
  position: relative;
  text-align: center;
  color: #c0c0c0;
  font-size: 7.8px;
}

/* Left side animation */
.nft-cube.left {
  transform: translateZ(-52px) rotateY(37deg);
}
.nft-cube.left .nft-cube-right {
  transform: rotateY(42deg) translateZ(46.8px);
}

/* Right side animation */
.nft-cube.right {
  transform: translateZ(-52px) rotateY(-45deg);
}
.nft-cube.right .nft-cube-right {
  transform: rotateY(42deg) translateZ(46.8px);
}
.nft-cube.right .nft-cube-right {
  border: 2px solid #32cd32;
}

/* ✅ Pulsating Green Glow Effect */
@keyframes pulsateGlow {
  0% {
    box-shadow: 0 0 9.75px rgba(50, 255, 50, 0.7), 0 0 19.5px rgba(50, 255, 50, 0.4);
  }
  50% {
    box-shadow: 0 0 16.25px rgba(50, 255, 50, 1), 0 0 32.5px rgba(50, 255, 50, 0.8);
  }
  100% {
    box-shadow: 0 0 9.75px rgba(50, 255, 50, 0.7), 0 0 19.5px rgba(50, 255, 50, 0.4);
  }
}

/* ✅ Apply Glow to Each Side of the Cube */
.nft-cube-left.glow,
.nft-cube-right.glow {
  animation: pulsateGlow 1.5s infinite alternate ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
}

/* ✅ Ensure Glow Effect Extends Beyond Edges */
.nft-cube-left.glow {
  box-shadow: -6.5px 0px 16.25px rgba(50, 255, 50, 0.9), -13px 0px 26px rgba(50, 255, 50, 0.7);
}

.nft-cube-right.glow {
  box-shadow: 6.5px 0px 16.25px rgba(50, 255, 50, 0.9), 13px 0px 26px rgba(50, 255, 50, 0.7);
}

.nft-cube-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center title and cube */
  gap: 10px; /* Add spacing between title and cube */
}

.nft-cube-title {
  font-size: clamp(0.4rem, 0.7vw, 0.75rem);
  text-align: center;
  color: #f0f0f0;
  white-space: normal;
}
