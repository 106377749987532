/* Consolidate body styles into index.css */
body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 🎮 Overwatch-Style Neon Scrollbar (Blue + Green Glow) */
::-webkit-scrollbar {
  width: 9px; /* ✅ Increased width */
  height: 9px; /* ✅ Ensures horizontal scrollbar matches */
}

/* 🟢 Track (Scrollbar Background) */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #0b132b, #1c2541);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 255, 150, 0.2);
}

/* 🔵 Thumb (Draggable Scrollbar) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #00eaff, #00ff87);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 255, 180, 0.9);
  transition: all 0.3s ease-in-out;
}

/* 💠 Hover Effect */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #00ffea, #00ffb2);
  box-shadow: 0px 0px 15px rgba(0, 255, 200, 1);
}

/* ✅ Make Scrollbar Work for Both Vertical and Horizontal */
.scrollable-container {
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox compatibility */
}

/* 🎯 Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

